import { Observable } from 'rxjs';

export interface AdminMenu {
  name: string;
  url: string;
  icon: string;
  hasPermission?: Observable<boolean>;
}

export class GlobalConstants {
  // public static apiURL: string = "https://itsolutionstuff.com/";
  public static authMenu: Array<AdminMenu> = [
    { name: 'Home', url: 'auth/home', icon: 'home' },
    { name: 'Assessments', url: 'auth/documents', icon: 'archive' },
    { name: 'Help', url: 'auth/help', icon: 'help' },
    { name: 'Login', url: 'auth/login', icon: 'log-in' },
    { name: 'Settings', url: 'auth/settings', icon: 'cog' },
  ];

  static UploadImagesNumber: any;
}

export var UploadImagesNumber = {
  number: 2,
};
