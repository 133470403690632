import { Injectable } from '@angular/core';
import { Archive, Property, PropertyId, PropertyList } from '../types';
import { ApiService } from 'app/shared/api/services/api-call.service';
import { v4 as uuidv4 } from 'uuid';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  constructor(private api: ApiService) {}

  async addProperty(data: Property): Promise<PropertyId> {
    return await this.api._post<PropertyId>('property', { data: data });
  }
  async updateProperty(data: Property, id: number): Promise<Property> {
    if (data.uploadLogo) {
      const blob = await fetch(data.logo).then(r => r.blob());
      let form = new FormData();
      form.append('uuid', id as unknown as string);
      form.append(`image-0`, blob, uuidv4() + blob.type.replace('image/', '.'));
      const paths = await this.api._post<Array<string>>('upload/image', form);
      data.logo = paths[0];
      await this.api._patch<number>(`property/${id}`, {
        data: data,
      });
      return Promise.resolve(data);
    }
  }
  async deleteProperty(id: number): Promise<void> {
    return await this.api._delete<void>(`property/${id}`);
  }
  async archiveProperty(property: PropertyList) {
    return await this.api._patch<void>(`property/${property.property_id}`, {
      archived: property.archived ? 0 : 1,
    });
  }
  async loadPropertyList(archive: Archive): Promise<Array<PropertyList>> {
    return this.api._get<Array<PropertyList>>(`properties/${archive}`);
  }
}
