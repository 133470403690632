import { ImagesList } from '../types';
import { Document } from '../../document/types';
import { ReportOptionsReferenceType } from '../../reports/types';
import { Files } from 'app/types';

export namespace PhotoFeed {
  export class ChangeType {
    static readonly type = '[PhotoFeed] Change Type';
    constructor(public type: ReportOptionsReferenceType) {}
  }
  export class UpdateAreas {
    static readonly type = '[PhotoFeed] Update Areas';
    constructor(public images: Map<string, ImagesList> | Array<Files>) {}
  }
  export class ChangeData {
    static readonly type = '[PhotoFeed] Change Data';
    constructor(public data: Document) {}
  }
  export class Upload {
    static readonly type = '[PhotoFeed] Upload';
  }
  export class ChangeUploadSize {
    static readonly type = '[PhotoFeed] Change Upload Size';
    constructor(public size: number) {}
  }
  export class ChangeProgress {
    static readonly type = '[PhotoFeed] Change Progress';
    constructor(public progress: number) {}
  }
  export class ChangeImagesEdited {
    static readonly type = '[PhotoFeed] Change Images Edited';
    constructor(public edited: boolean) {}
  }
  export class Reset {
    static readonly type = '[PhotoFeed] Reset';
  }
}
