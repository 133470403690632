import { Authorization } from '../types';

export namespace Auth {
  export class CheckConnection {
    static readonly type = '[Connection] CheckConnection';
    constructor(public connection: { connected: boolean; connectionType: string }) {}
  }
  export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: { login: string; password: string }) {}
  }
  export class LoadFromStorage {
    static readonly type = '[Auth] LoadFromStorage';
    constructor() {}
  }
  export class SetGuestToken {
    static readonly type = '[Auth] SetGuestToken';
    constructor(public payload: string) {}
  }
  export class Logout {
    static readonly type = '[Auth] Logout';
  }
  export class CheckSession {
    static type = '[Auth] CheckSession';
  }
  export class LogoutSuccess {
    static type = '[Auth] LogoutSuccess';
  }

  export class LoginRedirect {
    static type = '[Auth] LoginRedirect';
  }

  export class LoginSuccess {
    static type = '[Auth] LoginSuccess';
    constructor(public authorization: Authorization) {}
  }
  export class LoginFailed {
    static type = '[Auth] LoginFailed';
  }
  export namespace Permission {
    export class Update {
      static type = '[Auth] Permission Update';
    }
  }
}
