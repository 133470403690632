import { NgModule } from '@angular/core';
import { PermissionWrapperPage } from './permission-wrapper.page';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './directives/permission-render.directive';
import { PermissionDisablerPipe } from './pipes/permission-disabler.pipe';

@NgModule({
  declarations: [PermissionWrapperPage, HasPermissionDirective, PermissionDisablerPipe],
  imports: [CommonModule],
  exports: [PermissionWrapperPage, HasPermissionDirective, PermissionDisablerPipe],
  providers: [HasPermissionDirective],
})
export class PermissionWrapperModule {}
