import { Component, OnInit } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { Select, Store } from '@ngxs/store';
import { from, lastValueFrom, Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { OfflineStorageState } from './shared/offline/state/offline-storage.state';
import { OfflineStorage } from './shared/offline/state/offline-storage.actions';
import { NetworkState } from './shared/state/network/network.state';
import { NetworkActions } from './shared/state/network/network.actions';
import { PropertyListState } from './admin-layout/pages/properties/state/property-list.state';
import { Router } from '@angular/router';
import { AuthState } from './shared/auth/state/auth.state';
import { SettingsState } from './shared/pages/settings/state/settings.state';
import { SettingsService } from './shared/pages/settings/settings.service';
import { environment } from 'environments/environment';
import { Auth } from './shared/auth/state/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    private platform: Platform,
    private store: Store,
    private storage: Storage,
    private settings: SettingsService
  ) {
    SplashScreen.show();
    this.store.dispatch(new OfflineStorage.Create());
    this.initializeApp();
    if (window && environment.production === false) {
      (window as any).ngxs = {
        store: this.store,
      };
      (window as any).login = async () => {
        try {
          console.log('Login start');
          await lastValueFrom(this.store.dispatch(new Auth.Login({ login: 'mr.psovod@gmail.com', password: '12345' })));
          const isAuthenticated = await this.store.selectSnapshot(AuthState.isAuthenticated);
          console.log('Login end', isAuthenticated);
        } catch (error) {
          console.error('Login error:', error);
        }
      };
    }
    // this.store.reset(this.store.snapshot());
  }

  @Select(NetworkState.isOffline) isOffline$: Observable<string>;
  @Select(AuthState.user) user$: Observable<string>;
  @Select(OfflineStorageState.isLoading) isLoading$: Observable<string>;
  @Select(PropertyListState.propertyName) propertyName$: Observable<string>;

  @Dispatch() public saveStorage = () => new OfflineStorage.Save();
  public networkStatus: any;
  networkListener: PluginListenerHandle;

  async ngOnInit() {
    this.store.dispatch(new NetworkActions.Listen());
    this.store.dispatch(new NetworkActions.CheckConnection());
    await this.storage.get('offline').then(res => {
      if (res != null) this.store.dispatch(new OfflineStorage.Load(res));
    });
    this.setDarkMode();
  }
  getItem(key: any): Observable<any> {
    return from(this.storage.get(key));
  }
  private setDarkMode() {
    document.body.classList.toggle('dark', this.store.selectSnapshot(SettingsState.isDarkMode));
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      SplashScreen.hide();
      if (environment.production) {
        await this.settings.checkVersion();
      }
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        try {
          let url = new URL(event.url);
          let params = new URLSearchParams(url.search);
          let uuid = params.get('uuid');
          let token = params.get('token');
          if (uuid && token) {
            this.router.navigateByUrl('auth/documents?uuid=' + uuid + '&token=' + token);
          }
        } catch (error) {
          alert('Invalid link');
          return;
        }
      });
      App.addListener('appStateChange', state => {
        if (!state.isActive) {
          this.saveStorage();
        }
      });
    });
  }
}
