export namespace PhotoUploadActions {
  export class SetSize {
    static readonly type = '[Photo Upload] Set File Size';
    constructor(public payload: number) {}
  }
  export class UploadedSize {
    static readonly type = '[Photo Upload] Uploaded File Size';
    constructor(public payload: number) {}
  }
  export class SetUploading {
    static readonly type = '[Photo Upload] Set Uploading';
    constructor(
      public isUploading: boolean,
      public uuid: string,
    ) {}
  }
  export class Reset {
    static readonly type = '[Photo Upload] Reset';
  }
}
