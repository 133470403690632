import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from '../../auth/state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private apiURL: string = environment.api;

  constructor(private http: HttpClient, private store: Store) {}

  public setBaseURL(url: string): void {
    this.apiURL = url;
  }
  private setAuthorizationHeader() {
    const authToken = this.store.selectSnapshot(AuthState.token);
    const guestToken = this.store.selectSnapshot(AuthState.guestToken) || 'null';
    return authToken ? { Authorization: 'Bearer ' + authToken } : { 'X-Tenant-Token': guestToken };
  }

  public get<T>(endpoint: string, options?): Observable<T> {
    const options_ = {
      headers: this.setAuthorizationHeader(),
      ...options,
    };
    return this.http.get<T>(this.apiURL + endpoint, options_) as unknown as Observable<T>;
  }

  public post(endpoint: string, body: any, customHeaders?: HttpHeaders, responseType?): Observable<HttpEvent<any>> {
    return this.http.post(this.apiURL + endpoint, body, {
      headers: this.setAuthorizationHeader(),
      ...customHeaders,
      reportProgress: true,
      observe: 'events',
      responseType: responseType ? responseType : 'json',
    });
  }

  public __post<T>(endpoint: string, body: any, customHeaders?: HttpHeaders): Observable<T> {
    return this.http.post<T>(this.apiURL + endpoint, body, {
      headers: this.setAuthorizationHeader(),
      ...customHeaders,
    });
  }
  public patch<T>(endpoint: string, body: any, customHeaders?: HttpHeaders): Observable<HttpEvent<T>> {
    return this.http.patch<T>(this.apiURL + endpoint, body, {
      headers: this.setAuthorizationHeader(),
      ...customHeaders,
      reportProgress: true,
      observe: 'events',
    });
  }

  public put<T>(endpoint: string, body: any, customHeaders?: HttpHeaders): Observable<any> {
    return this.http.put<T>(this.apiURL + endpoint, body, {
      headers: this.setAuthorizationHeader(),
      ...customHeaders,
      reportProgress: true,
      observe: 'events',
    });
  }
  public __put<T>(endpoint: string, body: any, customHeaders?: HttpHeaders, responseType?): Observable<T> {
    return this.http.put<T>(this.apiURL + endpoint, body, {
      headers: this.setAuthorizationHeader(),
      ...customHeaders,
      reportProgress: true,
      responseType: responseType ? responseType : 'json',
    });
  }

  public delete<T>(endpoint: string, customHeaders?: HttpHeaders): Promise<T> {
    return this.http
      .delete<T>(this.apiURL + endpoint, {
        headers: this.setAuthorizationHeader(),
        ...customHeaders,
      })
      .toPromise();
  }
}
