import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
// import { Auth } from "../pages/login/state/auth.actions";
// import { GlobalConstants } from "../shared/variables/global";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"]
})
export class AdminLayoutComponent implements OnInit {

  ngOnInit() {

  }

}
