import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Auth } from './auth.actions';
import { Navigate } from '@ngxs/router-plugin';
import { AlertService } from 'app/shared/services/alert/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OfflineStorage } from 'app/shared/offline/state/offline-storage.actions';
import { PermissionService } from 'app/shared/pages/permission-wrapper/services/permission.service';
import { Permission } from 'app/shared/pages/permission-wrapper/types';
import { PhotoFeed } from 'app/shared/pages/photo-feed/state/photo-feed.actions';
import { firstValueFrom } from 'rxjs';
import { AuthStateModel, UserInfo } from '../types';
const defaults: AuthStateModel = {
  type: null,
  isAuthenticated: false,
  isLoading: false,
  authorization: null,
  user: null,
  permissions: null,
  guestToken: null,
};
@State<AuthStateModel>({
  name: 'auth',
  defaults,
})
@Injectable()
export class AuthState {
  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.authorization.plainTextToken || null;
  }
  @Selector()
  static guestToken(state: AuthStateModel): string | null {
    return state.guestToken ?? null;
  }
  @Selector()
  static permissions(state: AuthStateModel): Array<string> {
    return state.permissions;
  }
  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return state.isAuthenticated;
  }

  @Selector()
  static id(state: AuthStateModel): number | string {
    return state.user.id;
  }
  @Selector()
  static info(state: AuthStateModel): UserInfo {
    return state.user.data;
  }

  @Selector()
  static type(state: AuthStateModel): string {
    return state.type;
  }
  @Selector()
  static user(state: AuthStateModel): string {
    return state.user.data ? state.user.data.firstName + ' ' + state.user.data.lastName + ' User' : 'John Doe User';
  }
  @Selector()
  static isLoading(state: AuthStateModel): boolean {
    return state.isLoading;
  }
  constructor(private authService: AuthService, private alert: AlertService, private permission: PermissionService) {}

  @Action(Auth.LoadFromStorage)
  onLoadFromStorage(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({});
  }

  @Action(Auth.Login)
  async login(ctx: StateContext<AuthStateModel>, { payload }: Auth.Login) {
    ctx.setState(defaults);
    ctx.patchState({
      isLoading: true,
    });
    try {
      const response = await this.authService.login(payload);
      ctx.dispatch(new Auth.LoginSuccess(response));
    } catch (error) {
      ctx.dispatch(new Auth.LoginFailed());
    } finally {
      ctx.patchState({
        isLoading: false,
      });
    }
  }

  @Action(Auth.LoginSuccess)
  async onLoginSuccess(ctx: StateContext<AuthStateModel>, payload: Auth.LoginSuccess) {
    // this.authService.startAutoLogout();
    ctx.patchState({
      type: 'Admin',
      authorization: payload.authorization,
      isAuthenticated: true,
    });
    ctx.dispatch(new PhotoFeed.Reset());
    const user = await this.authService.getUser(payload.authorization.accessToken.tokenable_id);
    ctx.patchState({
      user: user,
    });
    await firstValueFrom(ctx.dispatch(new Auth.Permission.Update()));
    switch ('Admin') {
      case 'Admin':
        ctx.dispatch(new Navigate(['/admin/properties']));
        break;

      default:
        ctx.dispatch(new Navigate(['/auth/home']));
        break;
    }
  }
  @Action(Auth.CheckConnection)
  onCheckConnection(ctx: StateContext<any>, action: Auth.CheckConnection) {
    ctx.patchState({
      isConnected: action.connection,
    });
  }
  @Action(Auth.SetGuestToken)
  async onUpdateToken(ctx: StateContext<AuthStateModel>, action: Auth.SetGuestToken) {
    ctx.patchState({
      guestToken: action.payload,
    });
  }
  @Action(Auth.Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    return this.authService.logout().subscribe(() => {
      ctx.dispatch(new Auth.LogoutSuccess());
    });
  }

  @Action(Auth.LoginRedirect)
  onLoginRedirect(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new Navigate(['/auth/login']));
  }
  @Action([Auth.LoginFailed, Auth.LogoutSuccess])
  setUserStateOnFailure(ctx: StateContext<AuthStateModel>) {
    ctx.setState(defaults);
    ctx.dispatch(new OfflineStorage.Logout());
    ctx.dispatch(new Auth.LoginRedirect());
  }
  @Action(Auth.Permission.Update)
  async onPermissionUpdate(ctx: StateContext<AuthStateModel>) {
    const permissions = await this.permission.getPermissions(ctx.getState().user);
    ctx.patchState({
      permissions: permissions,
    });
  }
}
