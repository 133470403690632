<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ng-container *ngIf="auth$ | async as auth; else admin">
      <ng-container *ngFor="let menu of adminMenu">
        <ion-tab-button *ngIf="menu.hasPermission | async" tab="{{ menu.url }}" (click)="route(menu)">
          <ion-icon
            [color]="selectedTab === menu.url ? 'success' : ''"
            name="{{ menu.icon + '-outline' }}"
            [md]="menu.icon"></ion-icon>
          <ion-label>{{ menu.name }}</ion-label>
        </ion-tab-button>
      </ng-container>
    </ng-container>
    <ng-template #admin>
      <ng-container *ngFor="let menu of authMenu">
        <ion-tab-button tab="{{ menu.url }}" (click)="route(menu)" [id]="menu.name">
          <ion-icon
            [color]="selectedTab === menu.url ? 'success' : ''"
            name="{{ menu.icon + '-outline' }}"
            [md]="menu.icon"></ion-icon>
          <ion-label>{{ menu.name }}</ion-label>
        </ion-tab-button>
      </ng-container>
    </ng-template>
  </ion-tab-bar>
</ion-tabs>
