import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SettingsActions } from './settings.actions';

export interface AppSettings {
  darkMode: boolean;
  imageQuality?: number;
  useWebCamera?: boolean;
}
const defaults = {
  darkMode: false,
  imageQuality: 100,
  useWebCamera: false,
};
@State<AppSettings>({
  name: 'settings',
  defaults,
})
@Injectable()
export class SettingsState {
  @Selector()
  static isDarkMode(state: AppSettings): boolean {
    return state.darkMode;
  }
  @Selector()
  static useWebCamera(state: AppSettings): boolean {
    return state.useWebCamera;
  }
  @Selector()
  static imageQuality(state: AppSettings): number {
    return state.imageQuality;
  }
  @Action(SettingsActions.Save)
  save(ctx: StateContext<AppSettings>, action: SettingsActions.Save) {
    ctx.patchState(action.settings);
  }
}
