import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PhotoUploadActions } from './photo-upload.actions';
import { LoaderService } from 'app/shared/services/loading/loader.service';
export class PhotoUploadStateModel {
  public size: number;
  public uploaded: number;
  public isUploading: boolean;
  public documentUuid: string;
}

const defaults = {
  size: null,
  uploaded: null,
  isUploading: false,
  documentUuid: null,
};

@State<PhotoUploadStateModel>({
  name: 'photoUpload',
  defaults,
})
@Injectable()
export class PhotoUploadState {
  constructor(private loaderService: LoaderService) {}
  @Selector()
  static size(state: PhotoUploadStateModel): number | null {
    return state.size;
  }
  @Selector()
  static uploaded(state: PhotoUploadStateModel): number {
    return state.uploaded;
  }
  @Selector()
  static isUploading(state: PhotoUploadStateModel): boolean {
    return state.isUploading;
  }
  @Selector()
  static documentUuid(state: PhotoUploadStateModel): string {
    return state.documentUuid;
  }
  @Selector()
  static progress(state: PhotoUploadStateModel): number {
    return state.uploaded / state.size;
  }

  @Action(PhotoUploadActions.UploadedSize)
  reduceSize(
    { getState, patchState }: StateContext<PhotoUploadStateModel>,
    { payload }: PhotoUploadActions.UploadedSize
  ) {
    patchState({
      uploaded: getState().uploaded + payload,
    });
  }
  @Action(PhotoUploadActions.SetUploading)
  setUploading(
    { patchState }: StateContext<PhotoUploadStateModel>,
    { isUploading, uuid }: PhotoUploadActions.SetUploading
  ) {
    patchState({
      isUploading: isUploading,
      documentUuid: uuid,
    });
  }
  @Action(PhotoUploadActions.Reset)
  reset({ setState }: StateContext<PhotoUploadStateModel>) {
    setState(defaults);
  }
}
