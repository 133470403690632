import { Injectable } from '@angular/core';
import { Document } from 'app/shared/pages/document/types';
import { Offline } from '../state/offline-storage.state';

@Injectable({
  providedIn: 'root',
})
export class OfflineStorageService {
  constructor() {}
  public onOfflineStorageAdd(storage: Array<Offline>, document: Document): Array<Offline> {
    let offline: Offline = storage.find((offline: Offline) => offline.propertyId === document.property_id);
    if (offline) {
      offline.documentList.push(document);
      return storage;
    } else {
      return storage.concat({
        documentList: [document],
        propertyId: document.property_id,
      });
    }
  }
  public onOfflineStorageRemove(storage: Array<Offline>, id: string): Array<Offline> {
    return storage.map((offline: Offline) => {
      offline.documentList = offline.documentList.filter((document: Document) => document.uuid !== id);
      console.log(offline);
      return offline;
    });
  }
}
