import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { AuthGuard } from './shared/guards/auth.guard';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { Drivers } from '@ionic/storage';

import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { CurrencyPipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { PropertyListState } from './admin-layout/pages/properties/state/property-list.state';
import { OfflineStorageState } from './shared/offline/state/offline-storage.state';
import { NetworkState } from './shared/state/network/network.state';

import * as SentryAngular from '@sentry/angular-ivy';
import * as Sentry from '@sentry/capacitor';
import { Replay } from '@sentry/replay';
import { PhotoUploadState } from './shared/services/upload/state/photo-upload.state';
import { PermissionWrapperModule } from './shared/pages/permission-wrapper/permission-wrapper.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TabsPage } from './shared/pages/tabs/tabs.page';
import { AuthState } from './shared/auth/state/auth.state';
import { SettingsState } from './shared/pages/settings/state/settings.state';
import { register } from 'swiper/element/bundle';

register();
if (environment.production) {
  Sentry.init(
    {
      dsn: 'https://1d401a3b927cc0fe490a2e9a61ed40ed@o4505876124336128.ingest.sentry.io/4505876129251328',
      // To set your release and dist versions
      release: 'birdrockres@' + environment.version,
      dist: '1',
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      integrations: [
        new Replay({
          networkDetailAllowUrls: ['*'],
          networkRequestHeaders: ['X-Custom-Header'],
          networkResponseHeaders: ['X-Custom-Header'],
        }),
        new SentryAngular.BrowserTracing({
          tracePropagationTargets: ['localhost', environment.api],
          routingInstrumentation: SentryAngular.routingInstrumentation,
        }),
      ] as any,
    },

    // Forward the init method from @sentry/angular
    SentryAngular.init
  );
}
@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, TabsPage],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgSelectModule,
    ColorPickerModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgxsModule.forRoot([
      PropertyListState,
      AuthState,
      OfflineStorageState,
      NetworkState,
      PhotoUploadState,
      SettingsState,
    ]),
    NgxsDispatchPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    PermissionWrapperModule,

    // NgxsLoggerPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    IonicStorageModule.forRoot({
      // Ensure name is the same here
      name: '__offline_upload_db',
      driverOrder: [Drivers.IndexedDB],
    }),

    NgxsStoragePluginModule.forRoot({
      key: ['auth', 'tenantDocument', 'settings'],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FontAwesomeModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthGuard,
    CurrencyPipe,
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler(),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentryAngular.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
