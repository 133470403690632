import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Permission, User } from '../types';
import { UserManagementService } from './user-management.service';
import { AuthState } from 'app/shared/auth/state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  permissions: Array<string> = [];
  constructor(private userManagement: UserManagementService, private store: Store) {
    this.permissions = this.store.selectSnapshot(AuthState.permissions);
  }
  async hasPermission(permission: Array<string> | string): Promise<boolean> {
    if (this.isTenant) return true;
    if (this.permissions === null) return false;
    try {
      if (typeof permission === 'string') {
        return this.permissions.includes(permission);
      } else {
        return permission.some((permission: string) => this.permissions.includes(permission));
      }
    } catch (error) {
      console.error('Error getting permissions:', error);
      throw error;
    }
  }
  public async getPermissions(user: User): Promise<Array<string>> {
    try {
      const { permissions } = await this.userManagement.getRole(user.role.id);
      return user.permissions.concat(permissions).map((permission: Permission) => permission.name);
    } catch (error) {
      console.error('Error getting permissions:', error);
      throw error;
    }
  }
  private isTenant(): boolean {
    return !this.store.selectSnapshot(AuthState.isAuthenticated);
  }
}
