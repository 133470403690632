import { Injectable, inject } from '@angular/core';
import { AlertController, AlertInput } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ApiService } from 'app/shared/api/services/api-call.service';
import { AssessmentType } from 'app/shared/pages/document/types';
import { GlobalSettings } from 'app/types';
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private api = inject(ApiService);
  private radioButtons: AlertInput[] = [];
  private async createAssessmentType() {
    const settings = await this.api._get<GlobalSettings>('settings');
    const { assessmentTypes } = settings;
    this.radioButtons = assessmentTypes.map(type => {
      return {
        name: type.name,
        type: 'radio',
        label: type.name,
        value: type,
        handler: _ => {},
        checked: type.id === 0 ? true : false,
      };
    });
    this.radioButtons.push({
      name: 'custom',
      type: 'radio',
      label: 'Custom',
      value: { id: null, name: 'custom', allowTenantShare: false, description: 'custom' } as AssessmentType,
      handler: _ => {},
      checked: false,
    });
  }
  constructor(private alertController: AlertController, private store: Store) {
    this.createAssessmentType();
  }

  async presentAlertInput(callback, title, inputs: AlertInput[]) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: blah => {},
        },
        {
          text: 'Create',
          id: 'confirm-button',
          handler: c => {
            callback(c);
          },
        },
      ],
    });
    await alert.present();
  }
  async presentAlertDelete(callback, header, message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header,
      message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: blah => {},
        },
        {
          text: 'Delete',
          role: 'destructive',
          id: 'delete-button',
          handler: c => {
            callback(c);
          },
        },
      ],
    });
    await alert.present();
  }

  async presentAlert(header, msg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: msg,
      buttons: ['OK'],
    });
    await alert.present();
  }
  async presentMessageError(msg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Warning',
      message: msg,
      buttons: ['OK'],
    });
    await alert.present();
  }
  async presentAlertSync(callback) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Sync App',
      message: 'Do you want to sync with the server?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: blah => {},
        },
        {
          text: 'Sync',
          id: 'sync-button',
          handler: c => {
            callback(c);
          },
        },
      ],
    });
    await alert.present();
  }
  async alertTest(callback) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Test pictures',
      message: 'This will add one picture to all areas of the test report',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: blah => {},
        },
        {
          text: 'Test',
          id: 'sync-button',
          handler: c => {
            callback('test-report');
          },
        },
      ],
    });
    await alert.present();
  }
  async presentAlertEditReportType(callback) {
    let text = { title: 'New Document Creation', buttonText: 'Create' };
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: text.title,
      message: 'Assessment Type',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: blah => {},
        },
        {
          text: text.buttonText,
          id: 'confirm-button',
          handler: c => {
            callback(c);
          },
        },
      ],
      inputs: this.radioButtons,
    });
    await alert.present();
  }
  async presentAlertCreateReport(callback, text = { title: 'New Document Creation', buttonText: 'Create' }) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: text.title,
      message: 'Assessment Type',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: blah => {},
        },
        {
          text: text.buttonText,
          id: 'confirm-button',
          handler: c => {
            callback(c);
          },
        },
        // {
        //   text: 'create-test-report',
        //   id: 'create-test-report',
        //   handler: (c) => {
        //     callback('test-report')
        //   }
        // },
      ],
      inputs: this.radioButtons,
    });
    await alert.present();
  }
  async presentAlertMultipleButtons(callback) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Help',
      message: 'Do you want to show tutorial',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: blah => {},
        },
        {
          text: 'Open',
          id: 'confirm-button',
          handler: callback,
        },
      ],
      inputs: [
        {
          name: 'remember',
          type: 'checkbox',
          label: 'Do not show',
          handler: val => {},
        },
      ],
    });
    await alert.present();
  }
}
