import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store, NgxsOnInit } from '@ngxs/store';
import { PropertyList } from '../types';
import { PropertyService } from '../services/property.service';
import { PropertyListStateActions } from './property-list.actions';
import { OfflineStorage } from 'app/shared/offline/state/offline-storage.actions';
import { NetworkState } from 'app/shared/state/network/network.state';
// import { OfflineStorageState } from 'app/admin-layout/state/offline-storage.state';
export interface PropertyListStateModel {
  list: Array<PropertyList>;
  selected: PropertyList | null;
}
const defaults: PropertyListStateModel = {
  list: null,
  selected: null,
};
@State<PropertyListStateModel>({
  name: 'propertyList',
  defaults,
})
@Injectable()
export class PropertyListState {
  constructor(private propertyService: PropertyService, private store: Store) {}

  @Selector()
  static list(state: PropertyListStateModel): Array<PropertyList> | null {
    return state.list;
  }
  @Selector()
  static id(state: PropertyListStateModel): number {
    return state.selected.property_id;
  }
  @Selector()
  static property(state: PropertyListStateModel): PropertyList | null {
    return state.selected;
  }
  @Selector()
  static propertyName(state: PropertyListStateModel): string | null {
    return state.selected ? state.selected?.data.propertyName + ' • ' + state.selected?.data.propertyPostal : null;
  }
  @Action(PropertyListStateActions.Select)
  onSelect(ctx: StateContext<PropertyListStateModel>, action: PropertyListStateActions.Select) {
    ctx.setState((state: PropertyListStateModel) => ({
      ...state,
      selected: state.list.find(property => property.property_id == action.id),
    }));
  }

  @Action(PropertyListStateActions.Load)
  async onLoad(ctx: StateContext<PropertyListStateModel>, action: PropertyListStateActions.Load) {
    if (this.store.selectSnapshot(NetworkState.isConnected)) {
      const response = await this.propertyService.loadPropertyList(action.archive);
      ctx.setState({
        list: response,
        selected: null,
      });
      ctx.dispatch(new OfflineStorage.PropertyList.Add(ctx.getState().list));
    } else {
      //tohle zpusobi  Cannot access 'PropertyListState' before initialization
      // const offlineList = this.store.selectSnapshot(OfflineStorageState.propertyList);
      // ctx.setState({
      //   list: offlineList,
      //   selected: null,
      // });
    }
  }
  @Action(PropertyListStateActions.Add)
  async onAddProperty({ setState }, action: PropertyListStateActions.Add) {
    const { id } = await this.propertyService.addProperty(action.property);
    setState((draft: PropertyListStateModel) => {
      draft.list.unshift({
        property_id: id,
        archived: 0,
        data: action.property,
      });
      return draft;
    });
  }
  @Action(PropertyListStateActions.Update)
  async onUpdateProperty(context: StateContext<PropertyListStateModel>, action: PropertyListStateActions.Update) {
    const data = await this.propertyService.updateProperty(action.property, action.id);
    context.setState((state: PropertyListStateModel) => ({
      ...state,
      list: state.list.map(property => {
        if (property.property_id === action.id) {
          property.data = data;
        }
        return property;
      }),
    }));
  }
  @Action(PropertyListStateActions.Archive)
  async onArchiveProperty(context: StateContext<PropertyListStateModel>, action: PropertyListStateActions.Archive) {
    await this.propertyService.archiveProperty(action.property);
    context.setState((state: PropertyListStateModel) => ({
      ...state,
      list: state.list.map(property => {
        if (property.property_id === action.property.property_id) {
          property.archived = property.archived ? 0 : 1;
        }
        return property;
      }),
    }));
  }

  @Action(PropertyListStateActions.Delete)
  async onDeleteProperty(context: StateContext<PropertyListStateModel>, action: PropertyListStateActions.Delete) {
    await this.propertyService.deleteProperty(action.property.property_id);
    context.setState((state: PropertyListStateModel) => ({
      ...state,
      list: state.list.filter(property => property.property_id !== action.property.property_id),
    }));
  }
}
