import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { OfflineStorage } from './offline-storage.actions';
import { Storage } from '@ionic/storage';
import { Navigate } from '@ngxs/router-plugin';
import { Document } from 'app/shared/pages/document/types';
import { PropertyList } from '../../../admin-layout/pages/properties/types';
import { OfflineStorageService } from '../services/offline-storage.service';
import { NetworkState } from 'app/shared/state/network/network.state';
import { FileTags } from 'app/types';
import { PropertyListState } from '../../../admin-layout/pages/properties/state/property-list.state';
export interface Offline {
  documentList: Array<Document>;
  propertyId: number;
}

interface OfflineStorage {
  isLoading: boolean;
  offline: Array<Offline>;
  propertyList: Array<PropertyList>;
  images: Array<FileTags>;
  category: Array<FileTags>;
}

const defaults = {
  isLoading: false,
  offline: [],
  propertyList: [],
  images: [],
  category: [],
};
@State<OfflineStorage>({
  name: 'offline',
  defaults,
})
@Injectable()
export class OfflineStorageState {
  constructor(private storage: Storage, private store: Store, private offlineService: OfflineStorageService) {}

  @Selector()
  static isLoading(state: OfflineStorage): boolean {
    return state.isLoading;
  }

  @Selector([PropertyListState.property])
  static documentSelectedList(state: OfflineStorage, propertyList: PropertyList): Array<Document> {
    return state.offline
      .filter((offline: Offline) => offline.propertyId === propertyList.property_id)
      .map((offline: Offline) => offline.documentList)
      .flat();
  }
  @Selector()
  static documentList(state: OfflineStorage): Array<Document> {
    return state.offline.map((offline: Offline) => offline.documentList).flat();
  }
  @Selector()
  static propertyList(state: OfflineStorage): Array<PropertyList> {
    return state.propertyList;
  }
  @Selector()
  static images(state: OfflineStorage): Array<FileTags> {
    return state.images;
  }
  @Selector()
  static category(state: OfflineStorage): Array<FileTags> {
    return state.category;
  }

  @Action(OfflineStorage.Create)
  onCreateStorage(ctx: StateContext<OfflineStorage>) {
    this.storage.create();
  }

  @Action(OfflineStorage.Load)
  onStorageLoad(ctx: StateContext<OfflineStorage>, action: OfflineStorage.Load) {
    ctx.setState(action.data);
  }
  @Action(OfflineStorage.Save)
  onStorageSave(ctx: StateContext<OfflineStorage>) {
    this.storage.set('offline', ctx.getState());
  }

  @Action(OfflineStorage.Add)
  onAddOffline(ctx: StateContext<OfflineStorage>, action: OfflineStorage.Add) {
    ctx.patchState({
      offline: this.offlineService.onOfflineStorageAdd(ctx.getState().offline, action.document),
    });
    this.storage.set('offline', ctx.getState());
  }
  @Action(OfflineStorage.Remove)
  onClearOffline(ctx: StateContext<OfflineStorage>, action: OfflineStorage.Remove) {
    ctx.patchState({
      offline: this.offlineService.onOfflineStorageRemove(ctx.getState().offline, action.id),
    });
    this.storage.set('offline', ctx.getState());
  }
  @Action(OfflineStorage.Sync)
  onSyncWithServer(ctx: StateContext<OfflineStorage>) {
    if (!this.store.selectSnapshot(NetworkState.isConnected)) return;
  }

  @Action(OfflineStorage.Navigate)
  onNavigate(context: StateContext<OfflineStorage>, action: OfflineStorage.Navigate) {
    context.dispatch(new Navigate([action.path]));
  }

  @Action(OfflineStorage.Logout)
  onLogout(ctx: StateContext<OfflineStorage>) {
    this.storage.remove('offline');
    ctx.setState(defaults);
  }
  @Action(OfflineStorage.PropertyList.Add)
  onPropertyListAdd(ctx: StateContext<OfflineStorage>, action: OfflineStorage.PropertyList.Add) {
    ctx.patchState({
      propertyList: action.data,
    });
  }
  @Action(OfflineStorage.Tags.Add)
  onTagsAdd(ctx: StateContext<OfflineStorage>, { tags, type }: OfflineStorage.Tags.Add) {
    ctx.patchState({
      [type]: tags,
    });
  }
}
