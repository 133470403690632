export namespace NetworkActions {
  export class Listen {
    static readonly type = '[Network] Listen';
    constructor() {}
  }
  export class CheckConnection {
    static readonly type = '[Network] CheckConnection';
  }
}
export namespace TestNetworkStateActions {
  export class SetConnection {
    static readonly type = '[NetworkState] SetConnection';
    constructor(public isConnected: boolean) {}
  }
}
